:root {
  /* Color variables */
  --primary-color: #161817;
  --black: #161817;
  --background-color: #693FF3;
  --text-color: #F8F9FA;
  --timer-color: #e74c3c;
  --purple: #693FF3;
  --green: #2CA430;
  --red: #FF4747;
  --yellow: #FFDD00;
  --white: #F8F9FA;
  --lightpurple: #D4CEFD;
  --darkpurple: #311980;

  /* Spacing variables */
  --spacing-0: 0px;
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;

  /* Radius variables */
  --radius-0: 0px;
  --radius-1: 4px;
  --radius-2: 8px;
  --radius-3: 12px;
  --radius-4: 16px;
  --radius-5: 20px;
  --radius-6: 24px;
  --radius-7: 28px;
  --radius-8: 32px;
}

@font-face {
  /* Custom font */
  font-family: 'League-Spartan';
  src: url('LeagueSpartan-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  /* Custom font */
  font-family: 'Termina';
  src: url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-demi.eot');
  src: url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-demi.eot?#iefix') format('embedded-opentype'),
       url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-demi.woff2') format('woff2'),
       url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-demi.woff') format('woff'),
       url(https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-demi.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
	font-family: 'Termina-Bold';
	src: url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-bold.eot');
	src: url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-bold.eot?#iefix') format('embedded-opentype'),
            url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-bold.woff2') format('woff2'),
	     url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-bold.woff') format('woff'),
	     url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-bold.ttf') format('truetype'),
	     url('https://thedailyaus.com.au/wp-content/uploads/2024/01/termina-bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

div#root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

nav.navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  margin-top: 20px;
  background: var(--purple);
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 600px){
  nav.navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    margin: 12px 0px;
    background: var(--purple);
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.menu {
  padding: 0px 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.menuitem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

body {
  /* Body styles */
  background-color: var(--darkpurple);
  color: var(--white);
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px;
  padding: 0px;
}

p {
  /* Paragraph styles */
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  text-align: center;
}

@media screen and (max-width: 600px){
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    padding: 0px;
    margin: 0px;
    text-align: center;
  }
}

h1 {
  font-family: 'League-Spartan';
  text-transform: uppercase;
  font-size: 56px;
  text-align: center;
  padding: 0px;
  margin: 0px;
}

@media screen and (max-width: 600px){
  h1 {
    font-family: 'League-Spartan';
    text-transform: uppercase;
    font-size: 50px;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }
}

h2 {
  font-family: 'League-Spartan';
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  padding: 0px;
  margin: 0px;
}

@media screen and (max-width: 600px){
  h2 {
    font-family: 'League-Spartan';
    font-size: 30px;
    text-transform: normal;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }
}

h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 28px;
  text-align: center;
  padding: 0px;
  margin: 0px;
}

@media screen and (max-width: 600px){
  h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }
}

.Security {
  display: flex;
  height: 100vh;
  width: 300px;
  border-radius: 4px;
  border-radius: var(--radius-1);
  color: #f8f9fa;
  color: var(--text-color);
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border: none;
  padding: 24px;
  background: var(--purple);
  border-radius: 4px;
  width: 100%;
  table-layout: auto;
}

.table-responsive {
  overflow-x: auto;
  display: block;
}


tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  font-size: 30px;
  text-align: left;
}

form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
}

.logo {
  height: 75px;
  pointer-events: none;
  padding: 12px;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  /* Button styles */
  background: var(--green);
  padding-block: var(--spacing-3);
  padding-inline: var(--spacing-3);
  border-radius: var(--radius-1);
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: white;
}

button:hover, a:hover {
  cursor: pointer;
}

input[type="text"] {
  /* Text input styles */
  padding-block: var(--spacing-3);
  padding-inline: var(--spacing-3);
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
}

input {
  padding-block: var(--spacing-3);
  padding-inline: var(--spacing-3);
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
}

.utilitywrapper {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: var(--purple);
  padding: 24px;
  border-radius: var(--radius-1);
}

.section h2 {
  padding: 20px 20px 0px 20px;
  text-align: left;
}